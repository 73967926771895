import React, { useCallback } from "react"
import classNames from 'classnames';
import styles from './index.module.scss';
import { useNavigate } from 'react-router-dom';
import { Button } from "../../components/Button";
import { useTranslation } from 'react-i18next';
import iconLock from '../../assets/images/icons/icon-lock.svg';

export function NoEmptyCellPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const goToMain = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const Field = () => (
    <div className={classNames(styles.cells__item, styles.cells__item_greey)}>
      <img src={iconLock} alt="lock" />
    </div>
  );

  const fields: JSX.Element[] = [];
  for (let i = 1; i <= 6; i++) {
    fields.push(<Field key={i} />);
  }

  return (
    <>
      <div className="inside">
        <div className="title">
          <p>{t('translation.noEmptyCellPage.title')}</p>
        </div>

        <div className={classNames(styles.cells, styles.cells__greey)}>
          {fields}
        </div>

      </div>
      <Button
        type="button"
        onClick={goToMain}
        text={t('translation.buttons.main')}
      />
    </>
  )
}
