import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { InfoBlocks } from "../../components/InfoBlocks";
import { useActions } from "../../hooks/useActions";

interface LocationState {
  state: {
    continueStorage: boolean;
    stopStorage: boolean;
  }
}

export function OrderSuccessPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location as LocationState;
  const { setPhoneNumber, setPinCountAccess, setPinCode, agreementHandler } = useActions();
  const goToMainPage = useCallback(() => {
    setPhoneNumber('');
    setPinCode('');
    agreementHandler();
    setPinCountAccess(3);
    navigate('/');
  }, [navigate, setPhoneNumber, setPinCode, agreementHandler, setPinCountAccess]);

  const {
    userReducer: { userPhone }
  } = useTypedSelector((state) => state);

  console.log(state);


  return (
    <>
      <div className="inside">
        <div className="title">
          {state && state.continueStorage && (
            <p>{t('translation.orderSuccessPage.continueStorage')}</p>
          )}
          {state && state.stopStorage && (
            <p>{t('translation.orderSuccessPage.finishStorage')}</p>
          )}
          {!state && (
            <p>{t('translation.orderSuccessPage.title')}</p>
          )}
        </div>

        {state && state.continueStorage &&(
          <div className="title-text">
            <p>{t('translation.orderSuccessPage.phoneInfo')}</p>
            <span>{userPhone || '-'}</span>
          </div>
        )}

        {!state && (
          <>
            <div className="title-text">
              <p>{t('translation.orderSuccessPage.phoneInfo')}</p>
              <span>{userPhone || '-'}</span>
            </div>
            <div className="title-text">
              <p>{t('translation.orderSuccessPage.codeInfo')}</p>
            </div>
          </>
        )}

        {state && state.stopStorage && (
          <div className="title-text">
            <p>{t('translation.orderSuccessPage.thanks')}</p>
          </div>
        )}

        <InfoBlocks type="success"/>
      </div>

      <Button
        type="button"
        text={t('translation.buttons.main')}
        onClick={goToMainPage}
      />
    </>
  )
}
