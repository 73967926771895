import React, { useCallback } from "react"
import classNames from 'classnames';
import styles from './index.module.scss';
import { useNavigate } from 'react-router-dom';
import { Button } from "../../components/Button";
import { Trans, useTranslation } from 'react-i18next';
import { Icon } from "./icon";
import { Link } from "react-router-dom";
import { useActions } from "../../hooks/useActions";

export function RulesPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { agreementHandler } = useActions();
  const goToNextPage = useCallback(() => {
    agreementHandler();
    navigate('/cell-number');
  }, [navigate, agreementHandler]);

  const forbiddenItems = [
    {
      title: t('translation.rulesPage.forbidden.poison'),
      icon: 'poison',
    },
    {
      title: t('translation.rulesPage.forbidden.damage'),
      icon: 'damage',
    },
    {
      title: t('translation.rulesPage.forbidden.guns'),
      icon: 'guns',
    },
    {
      title: t('translation.rulesPage.forbidden.fastFood'),
      icon: 'fastFood',
    },
    {
      title: t('translation.rulesPage.forbidden.money'),
      icon: 'money',
    },
    {
      title: t('translation.rulesPage.forbidden.drugs'),
      icon: 'drugs',
    },

  ]

  return (
    <>
      <div className="inside">
        <div className={classNames(styles.forbidden)}>
          <div className={classNames(styles.forbidden__title)}>
            <p>{t('translation.rulesPage.attention')}<br />
              {t('translation.rulesPage.title')}</p>
          </div>
          <div className={classNames(styles.forbidden__list)}>
            {forbiddenItems.map(item => {
              return (
                <div key={item.title}>
                  <div>
                    <Icon name={item.icon} />
                  </div>
                  <div>
                    <p>{item.title}</p>
                  </div>
                </div>
              )
            })}
          </div>
          <div className={classNames(styles.forbidden__footer)}>
            <p>
              <Trans
                i18nKey={('translation.rulesPage.link')}
                components={[
                  <Link to='/offer'/>,
                ]}
              />
            </p>
          </div>
        </div>
      </div>
      <Button
        type="button"
        onClick={goToNextPage}
        text={t('translation.buttons.continue')}
      />
    </>
  )
}
