import { Dispatch } from "redux";

import { UserActions } from "../actionTypes";
import { UserActionsTypes } from "../types/user/action";

export const setPinCode =
  (code: string) => async (dispatch: Dispatch<UserActionsTypes>) => {
    dispatch({
      type: UserActions.SET_PIN_CODE,
      payload: code,
    });
  };

export const setPhoneNumber =
  (number: string) => async (dispatch: Dispatch<UserActionsTypes>) => {
    dispatch({
      type: UserActions.SET_PHONE_NUMBER,
      payload: number,
    });
  };

export const setPinCountAccess =
  (count: number) => async (dispatch: Dispatch<UserActionsTypes>) => {
    dispatch({
      type: UserActions.SET_PIN_COUNT_ACCESS,
      payload: count,
    });
  };

export const agreementHandler = () => (dispatch: any) => {
  dispatch({
    type: UserActions.AGREE_WITH_CONDITION,
  });
};


