import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { InfoBlocks } from "../../components/InfoBlocks";
import { checkOrderRequest } from "../../helpers/api/api";
import { useActions } from "../../hooks/useActions";

interface LocationState {
  state: {
    continueStorage: boolean;
    stopStorage: boolean;
  }
}

export function PlacePackagePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location as LocationState;
  const { loadingHandler } = useActions();
  const [configData, setConfigData] = useState<checkOrderData | orderPlacingData | orderContinueData>();
  const {
    deviceReducer: { deviceUID, deviceData }
  } = useTypedSelector((state) => state);

  console.log('state', state);

  const {
    uiReducer: { isLoading }
  } = useTypedSelector((state) => state);

  const {
    userReducer: { userPhone, pinCode }
  } = useTypedSelector((state) => state);

  useEffect(() => {
    setConfigData({
      recipient_phone: userPhone,
      recipient_code: pinCode,
      device_id: deviceUID,
      device_uid: deviceUID,
    });
  }, [
    deviceUID,
    pinCode,
    userPhone,
  ]);

  // const checkOrder = useCallback(() => {
  //   loadingHandler();
  //   checkOrderRequest(configData as checkOrderData)
  //     .then(({ status, data }) => {
  //       console.log(data);
  //       if (status === 200) {
  //         if (state && state.continueStorage) {
  //           console.log('хранение продолжено');
  //           navigate('/order-success', {
  //             state: { continueStorage: true }
  //           });
  //         } else if (state && state.stopStorage) {
  //           navigate('/order-success', {
  //             state: { stopStorage: true }
  //           });
  //           console.log('остановить продление');
  //         } else {
  //           navigate('/order-success');
  //           console.log('вещи размещены');
  //         }
  //       }
  //     })
  //     .catch(error => {
  //       if (error.response) {
  //         if (state && state.continueStorage) {
  //           navigate('/order-not-complete', {
  //             state: { continueStorage: true }
  //           });
  //         } else if (state && state.stopStorage) {
  //           navigate('/order-not-complete', {
  //             state: { stopStorage: true }
  //           });
  //         } else {
  //           console.log('Открыта');
  //           navigate('/order-failed');
  //         }
  //       }
  //     })
  //     .finally(() => {
  //       loadingHandler();
  //     });
  // }, [
  //   configData,
  //   loadingHandler,
  //   navigate,
  //   state
  // ]);

  const checkOrder = () => navigate('/order-success', { state })

  return (
    <>
      <div className="inside">
        <div className="cells__title">
          <p>{deviceData.cellNumber || '-'}</p>
        </div>
        <div className="title title--type2">
          <p>{t('translation.placePackagePage.title')}</p>
        </div>
        <div className="title-text">
          {state && state.stopStorage && (
            <p>{t('translation.placePackagePage.close')}</p>
          )}
          {!state && (
            <p>{t('translation.placePackagePage.info')}</p>
          )}
        </div>
        <InfoBlocks type="bag" />
      </div>

      <Button
        type="button"
        text={state && state.continueStorage ? t('translation.buttons.continueStore') : t('translation.buttons.cellClosed')}
        onClick={checkOrder}
        disabled={!deviceData.cellNumber}
        loading={isLoading}
      />
    </>
  )
}
