import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";
import storage from "redux-persist/lib/storage";

import deviceReducer from "./reducers/deviceReducer";
import uiReducer from "./reducers/uiReducer";
import userReducer from "./reducers/userReducer";

const persistedDeviceConfig = {
  key: "deviceReducer",
  storage: storage,
  whitelist: ["deviceUID", "deviceData"],
};

const persistedUIConfig = {
  key: "uiReducer",
  storage: storage,
  whitelist: ["loadingHandler"],
};

const persistedUserConfig = {
  key: "userReducer",
  storage: storage,
  whitelist: ["isAuthorizedUser", "userPhone", "pinCode", "pinCountAccess"],
};

const persistedUI = persistReducer(persistedUIConfig, uiReducer);
const persistedDevice = persistReducer(persistedDeviceConfig, deviceReducer);
const persistedUser = persistReducer(persistedUserConfig, userReducer);

const createStoreWithMiddleware = composeWithDevTools(applyMiddleware(thunk))(
  createStore
);

const rootReducer = combineReducers({
  deviceReducer: persistedDevice,
  userReducer: persistedUser,
  uiReducer: persistedUI,
});

const store = createStoreWithMiddleware(rootReducer);

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;

export default store;
