import React from 'react';
import iconSuccess from '../../assets/images/icons/icon-check.svg';
import iconFailed from '../../assets/images/icons/icon-notallow.svg';
import iconLock from '../../assets/images/icons/icon-lock.svg';
import iconUnLock from '../../assets/images/icons/icon-unlock.svg';
import iconBag from '../../assets/images/icons/icon-bag.svg';
import iconImportant from '../../assets/images/icons/icon-important.svg';
import classNames from 'classnames';
import styles from './index.module.scss';

interface IInfoBlocksProps {
  type?: string;
  number?: string;
  isForPin?: boolean;
}

export const InfoBlocks = (props: IInfoBlocksProps) => {

  let icon = props.type;

  switch (props.type) {
    case 'success':
      icon = iconSuccess;
      break;
    case 'failed':
      icon = iconFailed;
      break;
    case 'bag':
      icon = iconBag;
      break;
    case 'notComplete':
      icon = iconImportant;
      break;
    case 'pin':
      icon = !props.isForPin ? iconLock : iconUnLock;
      break;
    default:
      icon = '';
  }

  const Field = ({ status }: { status: boolean }) => (
    <div className={
      classNames(
        styles.cells__item,
        status && `cells__item_${props.type || 'main'}`
        )
      }
    >
      {status && !props.number && <img src={icon} alt="icon" />}
      {status && props.number && <p>{props.number}</p>}
    </div>
  );

  const fields: JSX.Element[] = [];
  for (let i = 1; i <= 6; i++) {
    fields.push(<Field status={i === 2 ? true : false} key={i} />);
  }

  return (
    <div className={classNames(styles.cells)}>
      {fields}
    </div>
  );
}
