import { isProd } from "../../constants";
import { DeviceReducerActions } from "../actionTypes";
import { IDeviceState } from "../types/device/state";

const defaultState: IDeviceState = {
  deviceUID: !isProd ? '470004' : '',
  deviceData: {
    cellNumber: '',
    stackNumber: null,
    placement_code: '',
    orderUID: '',
  }
};

const deviceReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case DeviceReducerActions.SET_DEVICE_UID:
      return { ...state, deviceUID: action.payload };
    case DeviceReducerActions.SET_DEVICE_DATA:
      return { ...state, deviceData: action.payload };
    default:
      return state;
  }
};

export default deviceReducer;
