export enum DeviceReducerActions {
  SET_DEVICE_UID = "SET_DEVICE_UID",
  SET_DEVICE_DATA = "SET_DEVICE_DATA",
}

export enum UiActions {
  LOADING_HANDLER = "LOADING_HANDLER",
}

export enum UserActions {
  AGREE_WITH_CONDITION = "AGREE_WITH_CONDITION",
  SET_PIN_CODE = "SET_PIN_CODE",
  SET_PHONE_NUMBER = "SET_PHONE_NUMBER",
  SET_PIN_COUNT_ACCESS = "SET_PIN_COUNT_ACCESS",
}