import * as UIActionCreators from "./uiActions";
import * as DeviceActionCreators from "./deviceActions";
import * as UserActionCreators from "./userActions";

const actions = {
  ...UIActionCreators,
  ...DeviceActionCreators,
  ...UserActionCreators,
}

export default actions;
