import { UiActions } from "../actionTypes";

import { UIState } from "../types/ui/state";
import { UIActionsType } from "../types/ui/actions";

const defaultState: UIState = {
  isLoading: false,
};

const uiReducer = (state = defaultState, action: UIActionsType) => {
  switch (action.type) {
    case UiActions.LOADING_HANDLER:
      return { ...state, isLoading: !state.isLoading };

    default:
      return state;
  }
};

export default uiReducer;
