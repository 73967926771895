import { Dispatch } from "redux";
import { DeviceReducerActions } from "../actionTypes";
import { DeviceActions } from "../types/device/actions";

export const setDeviceUid =
  (uid: string) => async (dispatch: Dispatch<DeviceActions>) => {
    dispatch({
      type: DeviceReducerActions.SET_DEVICE_UID,
      payload: uid,
    });
  };

export const setDeviceData =
  (data: object) => async (dispatch: Dispatch<DeviceActions>) => {
    if (!data) {
      dispatch({
        type: DeviceReducerActions.SET_DEVICE_DATA,
        payload: { cellNumber: "", stackNumber: "", placement_code: "", orderUID: "", },
      });
      return;
    }

    dispatch({
      type: DeviceReducerActions.SET_DEVICE_DATA,
      payload: data,
    });
  };