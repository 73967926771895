import React, { useCallback } from "react"
import { useNavigate } from 'react-router-dom';
import { Button } from "../../components/Button";
import { useTranslation } from 'react-i18next';

export function OfferPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const goToNextPage = useCallback(() => {
    navigate('/cell-number');
  }, [navigate]);

  return (
    <>
      <div className="inside">
        <div className="title">
          <p>{t('translation.offerPage.title')}</p>
        </div>
      </div>
      <div className="description">
        <p>{t('translation.offerPage.info')}</p>
        <ol>
          <li>
            {t('translation.offerPage.1')}
            <ol>
              <li>{t('translation.offerPage.1-1')}</li>
              <li>{t('translation.offerPage.1-2')}</li>
              <li>{t('translation.offerPage.1-3')}</li>
              <li>{t('translation.offerPage.1-4')}</li>
              <li>{t('translation.offerPage.1-5')}</li>
              <li>{t('translation.offerPage.1-6')}</li>
              <li>{t('translation.offerPage.1-7')}</li>
              <li>{t('translation.offerPage.1-8')}</li>
              <li>{t('translation.offerPage.1-9')}</li>
              <li>{t('translation.offerPage.1-10')}</li>
              <li>{t('translation.offerPage.1-11')}</li>
              <li>{t('translation.offerPage.1-12')}</li>
              <li>{t('translation.offerPage.1-13')}</li>
            </ol>
          </li>
          <li>
            {t('translation.offerPage.2')}
            <ol>
              <li>{t('translation.offerPage.2-1')}</li>
              <li>{t('translation.offerPage.2-2')}</li>
              <li>{t('translation.offerPage.2-3')}</li>
              <li>{t('translation.offerPage.2-4')}</li>
              <li>{t('translation.offerPage.2-5')}</li>
            </ol>
          </li>
          <li>
            {t('translation.offerPage.3')}
            <ol>
              <li>{t('translation.offerPage.3-1')}</li>
              <li>{t('translation.offerPage.3-2')}</li>
              <li>{t('translation.offerPage.3-3')}</li>
              <li>{t('translation.offerPage.3-4')}</li>
              <li>{t('translation.offerPage.3-5')}</li>
              <li>{t('translation.offerPage.3-6')}</li>
              <li>{t('translation.offerPage.3-7')}</li>
            </ol>
          </li>
          <li>
            {t('translation.offerPage.4')}
            <ol>
              <li>{t('translation.offerPage.4-1')}</li>
              <li>{t('translation.offerPage.4-2')}</li>
              <li>{t('translation.offerPage.4-3')}</li>
              <li>{t('translation.offerPage.4-4')}</li>
              <li>{t('translation.offerPage.4-5')}</li>
              <li>{t('translation.offerPage.4-6')}</li>
              <li>{t('translation.offerPage.4-7')}</li>
              <li>{t('translation.offerPage.4-8')}</li>
            </ol>
          </li>
          <li>
            {t('translation.offerPage.5')}
            <ol>
              <li>{t('translation.offerPage.5-1')}</li>
              <li>{t('translation.offerPage.5-2')}</li>
              <li>{t('translation.offerPage.5-3')}</li>
              <li>{t('translation.offerPage.5-4')}</li>
              <li>{t('translation.offerPage.5-5')}</li>
              <li>{t('translation.offerPage.5-6')}</li>
              <li>{t('translation.offerPage.5-7')}</li>
              <li>{t('translation.offerPage.5-8')}</li>
              <li>{t('translation.offerPage.5-9')}</li>
              <li>{t('translation.offerPage.5-10')}</li>
              <li>{t('translation.offerPage.5-11')}</li>
              <li>{t('translation.offerPage.5-12')}</li>
              <li>{t('translation.offerPage.5-13')}</li>
            </ol>
          </li>
          <li>
            {t('translation.offerPage.6')}
            <ol>
              <li>{t('translation.offerPage.6-1')}</li>
              <li>{t('translation.offerPage.6-2')}</li>
              <li>{t('translation.offerPage.6-3')}</li>
              <li>{t('translation.offerPage.6-4')}</li>
            </ol>
          </li>
          <li>
            {t('translation.offerPage.7')}
            <ol>
              <li>{t('translation.offerPage.7-1')}</li>
              <li>{t('translation.offerPage.7-2')}</li>
              <li>{t('translation.offerPage.7-3')}</li>
              <li>{t('translation.offerPage.7-4')}</li>
            </ol>
          </li>
          <li>
            {t('translation.offerPage.8')}
            <ol>
              <li>{t('translation.offerPage.8-1')}</li>
              <li>{t('translation.offerPage.8-2')}</li>
              <li>{t('translation.offerPage.8-3')}</li>
              <li>{t('translation.offerPage.8-4')}</li>
              <li>{t('translation.offerPage.8-5')}</li>
            </ol>
          </li>
          <li>
            {t('translation.offerPage.9')}
            <ol>
              <li>{t('translation.offerPage.9-1')}</li>
              <li>{t('translation.offerPage.9-2')}</li>
              <li>{t('translation.offerPage.9-3')}</li>
              <li>{t('translation.offerPage.9-4')}</li>
            </ol>
          </li>
          <li>
            {t('translation.offerPage.10')}
            <ol>
              <li>{t('translation.offerPage.10-1')}</li>
              <li>{t('translation.offerPage.10-2')}</li>
            </ol>
          </li>
        </ol>
      </div>
      <Button
        type="button"
        onClick={goToNextPage}
        text={t('translation.buttons.continue')}
      />
    </>
  );
}
