import React, { useCallback, useEffect, useRef, useState } from "react"
import classNames from 'classnames';
import styles from './index.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from "../../components/Button";
import { useTranslation } from 'react-i18next';
import { InfoBlocks } from "../../components/InfoBlocks";
import { useActions } from "../../hooks/useActions";
import { checkOrderRequest, orderPlacingRequest } from "../../helpers/api/api";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { RecoverPinPage } from "../RecoverPinPage";
import { padLeadingZeros } from "../../helpers/helpers";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import PinInput from "react-pin-input";

export function EnterPinPage() {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state: userExists } = useLocation();
  const [configData, setConfigData] = useState<checkOrderData | orderPlacingData>();
  const [isRecoverMode, setIsRecoverMode] = useState(false);
  const { setPinCode, setPinCountAccess, setDeviceData, loadingHandler } = useActions();
  const [isPinCodeValid, setIsPinCodeValid] = useState(false);
  const {
    deviceReducer: { deviceUID, deviceData }
  } = useTypedSelector((state) => state);
  const {
    uiReducer: { isLoading }
  } = useTypedSelector((state) => state);
  const {
    userReducer: { userPhone, pinCountAccess }
  } = useTypedSelector((state) => state);

  const {
    formState: { errors },
    setError,
    clearErrors,
  } = useForm();

  const handleChange = useCallback(() => {
    setIsRecoverMode(false);
    setIsPinCodeValid(false);
  }, [])

  const ref = useRef<any>(null);
  const refDiv = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    refDiv.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    if (ref.current) {
      ref.current.elements.forEach((element: any, index: number) => {
        element.input.setAttribute('placeholder', '-');
        if (index !== 0) {
          element.input.setAttribute('disabled', true);
        }
      });
      ref.current.elements[0].input.focus();
    }
  }, [pinCountAccess, navigate, isRecoverMode])

  const handlePinChange = (pinCode: string) => {
    if (ref.current.elements[0].input.value !== '') {
      ref.current.elements[1].input.removeAttribute('disabled');
      ref.current.elements[1].input.focus();
    } else {
      ref.current.elements[1].input.value = '';
      ref.current.elements[2].input.value = '';
      ref.current.elements[3].input.value = '';
      ref.current.elements[0].input.setAttribute('placeholder', '-');
      ref.current.elements[1].input.setAttribute('disabled', true);
    }
    if (ref.current.elements[1].input.value !== '') {
      ref.current.elements[2].input.removeAttribute('disabled');
      ref.current.elements[2].input.focus();
    } else {
      ref.current.elements[2].input.value = '';
      ref.current.elements[3].input.value = '';
      ref.current.elements[1].input.setAttribute('placeholder', '-');
      ref.current.elements[2].input.setAttribute('disabled', true);
    }
    if (ref.current.elements[2].input.value !== '') {
      ref.current.elements[3].input.removeAttribute('disabled');
      ref.current.elements[3].input.focus();
    } else {
      ref.current.elements[3].input.value = '';
      ref.current.elements[2].input.setAttribute('placeholder', '-');
      ref.current.elements[3].input.setAttribute('disabled', true);
    }
    if (ref.current.elements[3].input.value === '') {
      ref.current.elements[3].input.setAttribute('placeholder', '-');
    }

    setIsPinCodeValid(false);
    if (pinCode.length === 4) {
      setPinCode(pinCode);
      setIsPinCodeValid(true);
      (document.activeElement as HTMLElement).blur();
      scrollToBottom();
      if (userExists) {
        setConfigData({
          recipient_phone: userPhone,
          recipient_code: pinCode,
          device_id: deviceUID,
          device_uid: deviceUID,
        });
      } else {
        setConfigData({
          device_id: deviceUID,
          device_uid: deviceUID,
          codes: {
            placement: deviceData.placement_code,
            recipient: pinCode
          }
        });
      }
    }
  };

  const placeOrder = useCallback(() => {
    loadingHandler();
    orderPlacingRequest(configData as orderPlacingData)
      .then(({ status, data }) => {
        console.log(data);
        console.log(status);
        if (status === 200) {
          navigate('/place-package');
        }
      })
      .catch(error => {
        if (error.response) {
          console.log(error.response);
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx

        }
      })
      .finally(() => {
        loadingHandler();
      });
  }, [configData, loadingHandler, navigate]);

  const checkOrder = useCallback(() => {
    loadingHandler();
    clearErrors();
    checkOrderRequest(configData as checkOrderData)
      .then(({ status, data }) => {
        console.log(data);
        if (status === 200) {
          let numberFormatted = null;
          const stack = data.data.reserved_cell.stack;
          const cell = data.data.reserved_cell.cell;
          const number = stack * 100 + cell + 1;
          numberFormatted = number.toString();
          setDeviceData({
            cellNumber: padLeadingZeros(numberFormatted, 3),
            stackNumber: data.data.reserved_cell.stack,
            orderUID: data.data.order_uid,
          });
          navigate('/cell-number', {
            state: { orderExists: true }
          });
        }
      })
      .catch(error => {
        if (error.response) {
          setPinCountAccess(pinCountAccess - 1);
          console.log(error.response);
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          switch (error.response.data.ids) {
            case 'invalid_recipient_code': return (
              setIsRecoverMode(true)
            );
            case 'device_offline': return (
              setError("pin_code", {
                type: "manual",
                message: t('translation.form.errorDeviceOffline'),
              })
            );
            default: return (
              setError("pin_code", {
                type: "manual",
                message: t('translation.form.errorResponse400'),
              }),
              setIsRecoverMode(false)
            )
          }
        }
      })
      .finally(() => {
        loadingHandler();
      });
  }, [
    t,
    pinCountAccess,
    setPinCountAccess,
    setDeviceData,
    configData,
    loadingHandler,
    navigate,
    clearErrors,
    setError,
  ]);

  useEffect(() => {
    if (pinCountAccess === 0)
      setIsRecoverMode(true);
    if (pinCountAccess === 3 && isRecoverMode) {
      setIsRecoverMode(false);
      setIsPinCodeValid(false);
    }
  }, [pinCountAccess, navigate, isRecoverMode]);

  return (
    <>
      {isRecoverMode ? (
        <RecoverPinPage onClick={handleChange} />
      ) : (
        <>
          <div className="inside">
            <div className="title">
              <p>{userExists ? t('translation.enterPinPage.enter') : t('translation.enterPinPage.title')}</p>
            </div>
            <div className={classNames(styles.order__cell_pin)}>
              <PinInput
                length={4}
                initialValue=""
                style={{}}
                inputStyle={{width: '46px', height: '80px', margin: '0 5px', borderStyle: 'solid', borderWidth: '2px', borderColor: 'transparent'}}
                inputFocusStyle={{borderColor: '#5db5e7'}}
                onChange={handlePinChange}
                type="numeric"
                inputMode="numeric"
                onComplete={(value, index) => {(document.activeElement as HTMLElement).blur()}}
                autoSelect={true}
                regexCriteria={/^[0-9]$/}
                focus={true}
                ref={ref}
              />
            </div>
            <InfoBlocks type="pin" isForPin={isPinCodeValid} />
            <ErrorMessage
              errors={errors}
              name="pin_code"
              render={
                ({ message }) =>
                  <p className="error">
                    {message}
                  </p>
              }
            />
          </div>
          <div ref={refDiv} className={classNames(styles.center)}>
            <Button
              type="button"
              onClick={userExists ? checkOrder : placeOrder}
              disabled={!isPinCodeValid}
              text={t('translation.buttons.confirm')}
              loading={isLoading}
            />
          </div>
        </>
      )}
    </>
  )
}
