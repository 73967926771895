import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { InfoBlocks } from "../../components/InfoBlocks";
import { checkOrderRequest } from "../../helpers/api/api";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";

interface LocationState {
  state: {
    continueStorage: boolean;
    stopStorage: boolean;
  }
}

export function OrderNotCompletePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location as LocationState;
  const { loadingHandler } = useActions();
  const [configData, setConfigData] = useState<checkOrderData | orderPlacingData>();
  console.log('state not-complete', state);

  const {
    deviceReducer: { deviceUID }
  } = useTypedSelector((state) => state);

  const {
    uiReducer: { isLoading }
  } = useTypedSelector((state) => state);

  const {
    userReducer: { userPhone, pinCode }
  } = useTypedSelector((state) => state);

  useEffect(() => {
    setConfigData({
      recipient_phone: userPhone,
      recipient_code: pinCode,
      device_id: deviceUID,
      device_uid: deviceUID,
    });
  }, [
    deviceUID,
    pinCode,
    userPhone,
  ]);

  const checkOrder = useCallback(() => {
    loadingHandler();
    checkOrderRequest(configData as checkOrderData)
      .then(({ status, data }) => {
        console.log(data);
        if (status === 200) {
          if (state && state.continueStorage) {
            console.log('хранение продолжено');
            navigate('/order-success', {
              state: { continueStorage: true }
            });
          } else if (state && state.stopStorage) {
            navigate('/order-success', {
              state: { stopStorage: true }
            });
            console.log('остановить продление');
          }
        }
      })
      .catch(error => {
        if (error.response) {
          if (state && state.continueStorage) {
            navigate('/order-not-complete', {
              state: { continueStorage: true }
            });
          } else if (state && state.stopStorage) {
            navigate('/order-not-complete', {
              state: { stopStorage: true }
            });
          }
        }
      })
      .finally(() => {
        loadingHandler();
      });
  }, [
    configData,
    loadingHandler,
    navigate,
    state
  ]);

  return (
    <>
      <div className="inside">
        <div className="title">
          <p>{t('translation.orderNotCompletePage.title')}</p>
        </div>
        <div className="title-text">
          <p>{t('translation.orderNotCompletePage.help')}</p>
        </div>
        <InfoBlocks type="notComplete"/>
      </div>

      <Button
        type="button"
        text={t('translation.buttons.cellClosed')}
        onClick={checkOrder}
        loading={isLoading}
      />
    </>
  )
}
