import React, { useCallback } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import styles from "./index.module.scss";

export function ChooseTariffPage() {
  const { t } = useTranslation();
  const goToNextPage = useCallback(() => {
    window.location.href = "https://ipay.ua";
  }, []);

  return (
    <>
      <div className="inside">
        <div className="title">
          <p>{t('translation.chooseTariffPage.title')}</p>
        </div>
        <div className={classNames(styles.chooseTariff)}>
          <div className={classNames(styles.chooseTariff__item)}>
            <label>
              <input className="hidden" type="radio" name="choose-tariff" />
              <p>1 година - 20 грн</p>
            </label>
          </div>
          <div className={classNames(styles.chooseTariff__item)}>
            <label>
              <input className="hidden" type="radio" name="choose-tariff" />
              <p>2 години - 40 грн</p>
            </label>
          </div>
          <div className={classNames(styles.chooseTariff__item)}>
            <label>
              <input className="hidden" type="radio" name="choose-tariff" />
              <p>3 години - 60 грн</p>
            </label>
          </div>
        </div>
      </div>

      <Button
        type="button"
        text={t('translation.buttons.pay')}
        onClick={goToNextPage}
      />
    </>
  )
}
