import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Button } from "../../components/Button";
import styles from "./index.module.scss";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import { createOrderRequest } from "../../helpers/api/api";
import { padLeadingZeros } from "../../helpers/helpers";
import PhoneInput from "react-phone-input-2";

export function InputNumberPage() {

  const {
    deviceReducer: { deviceUID }
  } = useTypedSelector((state) => state);

  const {
    uiReducer: { isLoading }
  } = useTypedSelector((state) => state);

  const {
    userReducer: { userPhone }
  } = useTypedSelector((state) => state);

  const [isValid, setIsValid] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [phone, setPhone] = useState('380');
  const refDiv = useRef<HTMLDivElement>(null);
  const [configData, setConfigData] = useState<orderData>();
  const {
    setDeviceData,
    setPhoneNumber,
    setPinCountAccess,
    setPinCode,
    loadingHandler,
  } = useActions();

  const {
    formState: { errors },
    setError,
    clearErrors,
  } = useForm();

  const scrollToBottom = () => {
    refDiv.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    if (phone && isValid) {
      (document.activeElement as HTMLElement).blur();
      scrollToBottom();
      setConfigData({
        recipient_phone: phone,
        status: '1',
        device_id: deviceUID,
        device_uid: deviceUID,
        order_type: '5',
      });
    }
  }, [phone, deviceUID, isValid]);

  const onSubmitClicked = () => {
    loadingHandler();
    clearErrors();
    setDeviceData({});
    createOrderRequest(configData as orderData)
      .then(({ status, data }) => {
        console.log(data);
        if (status === 201) {
          let numberFormatted = null;
          setPhoneNumber(data.data.recipient_phone);
          setPinCountAccess(3);
          setPinCode('');
          const stack = data.data.reserved_cell.stack;
          const cell = data.data.reserved_cell.cell;
          const number = stack * 100 + cell + 1;
          numberFormatted = number.toString();
          setDeviceData({
            cellNumber: padLeadingZeros(numberFormatted, 3),
            stackNumber: data.data.reserved_cell.stack,
            placement_code: data.data.codes.placement,
          });
          navigate('/rules');
        }
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (userPhone !== phone) {
            setPhoneNumber(phone);
            setPinCode('');
            setPinCountAccess(3);
          }
          console.log(error.response);
          switch (error.response.data.ids) {
            case 'device_offline': return (
              navigate('/no-connection')
            );
            case 'no_empty_cells': return (
              navigate('/no-empty-cells')
            );
            case 'order_already_exist': return (
              navigate('/enter-pin', {
                state: { userExists: true }
              })
            );
            default: return (
              setError("recipient_phone", {
                type: "manual",
                message: t('translation.form.errorResponse400'),
              })
            )
          }
        }
      })
      .finally(() => {
        loadingHandler();
      });
  };

  const onPhoneChange = useCallback((value, country) => {
    if (value.length === 11 && country.dialCode === '7') {
      setPhone(value);
      setIsValid(true);
    } else if (value.length === 12 && country.dialCode === '380') {
      setPhone(value);
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, []);

  return (
    <>
      <div className={classNames(styles.inputWrapper)}>
        <label htmlFor="phoneNumber" className={classNames(styles.label)}>
          {t('translation.form.inputLabel')}
        </label>

        <PhoneInput
          placeholder="+380 ХХ ХХХ ХХ ХХ"
          inputClass={classNames(styles.input, isValid ? styles.success : '')}
          value={phone}
          inputProps={{
            id: 'recipient_phone',
            required: true,
            inputMode: 'numeric',
            autoFocus: true
          }}
          onChange={(value, country) => onPhoneChange(value, country)}
          onlyCountries={['ua', 'ru']}
          masks={{ ua: '.. ... .. ..', ru: '... ... .. ..' }}
          specialLabel=''
        />
        <ErrorMessage
          errors={errors}
          name="recipient_phone"
          render={
            ({ message }) =>
              <p className="error">
                {message}
              </p>
          }
        />
      </div>
      <div ref={refDiv} className={classNames(styles.center)}>
        <Button
          type="button"
          text={t('translation.buttons.continue')}
          disabled={phone && isValid ? false : true}
          form="phoneForm"
          loading={isLoading}
          onClick={onSubmitClicked}
        />
      </div>
    </>
  )
}
