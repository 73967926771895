import React from 'react';
import backButton from '../../assets/images/icons/icon-back.svg';
import classNames from 'classnames';
import styles from './index.module.scss';

interface IBackButtonProps {
  onClick: () => void;
}

export function BackButton(props: IBackButtonProps) {
  return (
    <button
      type="button"
      className={classNames(styles.buttonBack)}
      onClick={props.onClick}
    >
      <img src={backButton} alt="Back" />
    </button>
  );
}
