import axios, { AxiosResponse } from "axios";
import { API_LINK, API_PIN_TOKEN } from '../../constants';

export const createOrderRequest = async (
  data: orderData
): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const saveOrder: AxiosResponse<ApiDataType> = await axios.post(
      API_LINK + 'api/2.0.0/order/storage/',
      data
    )
    return saveOrder
  } catch (e) {
    throw(e)
  }
}

export const orderPlacingRequest = async (
  data: orderPlacingData
): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const saveOrder: AxiosResponse<ApiDataType> = await axios.post(
      API_LINK + 'api/2.0.0/order/placing/storage/',
      data
    )
    return saveOrder
  } catch (e) {
    throw(e)
  }
}

export const checkOrderRequest = async (
  data: checkOrderData
): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const saveOrder: AxiosResponse<ApiDataType> = await axios.patch(
      API_LINK + 'api/2.0.0/order/receipt/storage/',
      data
    )
    return saveOrder
  } catch (e) {
    throw(e)
  }
}

export const receiveOrderRequest = async (
  data: checkOrderData
): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const receiveOrder: AxiosResponse<ApiDataType> = await axios.post(
      API_LINK + 'api/2.0.0/order/receipt/storage/',
      data
    )
    return receiveOrder
  } catch (e) {
    throw(e)
  }
}

// OCHN-1201 - remove  continued use of cell
// export const orderContinueRequest = async (
//   data: orderContinueData
// ): Promise<AxiosResponse<ApiDataType>> => {
//   try {
//     const continueOrderStorage: AxiosResponse<ApiDataType> = await axios.put(
//       API_LINK + 'api/2.0.0/order/receipt/storage/',
//       data
//     )
//     return continueOrderStorage
//   } catch (e) {
//     throw(e)
//   }
// }

export const resetPinCodeRequest = async (
  data: pinCodeData
): Promise<AxiosResponse<ApiDataType>> => {
  try {
    const headers = {
      'Authorization': `${API_PIN_TOKEN}`,
      'Content-Type': 'application/json',
    };
    const resetPinCode: AxiosResponse<ApiDataType> = await axios.put(
      API_LINK + 'api/2.0.0/order/receipt/code/',
      data,
      { headers: headers }
    )
    return resetPinCode
  } catch (e) {
    throw(e)
  }
}