import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import styles from './index.module.scss';
import iconUA from '../../assets/images/icons/icon-ua.svg';
import iconEN from '../../assets/images/icons/icon-en.svg';
import Modal from 'react-modal';
import i18next from 'i18next';
import { Lang } from '../../store/types/languages'
Modal.setAppElement("#root");

export function LanguageSelector() {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const toggleModal = useCallback(() => {
    setIsVisible(!isVisible);
  }, [isVisible]);

  const changeLanguage = (lng: string) => {
    i18next.changeLanguage(lng);
  };

  return (
    <div className={classNames(styles.lang)}>
      <div onClick={toggleModal}>
        <img
          src={
            i18next.language === Lang.UA
              ? iconUA
              : iconEN
          }
          alt="lang"
        />
      </div>

      <Modal
        isOpen={isVisible}
        onRequestClose={toggleModal}
        className={classNames(styles.modal)}
      >
        <p>{t('translation.main.langChoose')}</p>
        <button
          type='button'
          className={
            classNames(i18next.language === Lang.UA && styles.active)
          }
          onClick={() => changeLanguage(Lang.UA)}
        >
          <img src={iconUA} alt="lang" /> {t('translation.main.ua')}
        </button>
        <button
          type='button'
          className={
            classNames(i18next.language === Lang.EN && styles.active)
          }
          onClick={() => changeLanguage(Lang.EN)}
        >
          <img src={iconEN} alt="lang" /> {t('translation.main.en')}
        </button>
      </Modal>

    </div>
  );
}
