import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// load i18next locales
import en from './en/translation.json';
import ua from './ua/translation.json';

const translationsJson = {
  en: {
    translation: en,
  },
  ua: {
    translation: ua,
  },
};

export const languages = Object.keys(translationsJson);

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'ua',
    debug: true,
    resources: translationsJson,
    react: {
      useSuspense: true,
    },
    supportedLngs: languages,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });


export default i18n;