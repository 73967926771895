import { UserActions } from "../actionTypes";

import { IUserState } from "../types/user/state";
import { UserActionsTypes } from "../types/user/action";

const defaultState: IUserState = {
  userPhone: "",
  pinCode: "",
  isAgreeWithConditions: false,
  pinCountAccess: 3,
};

const userReducer = (state = defaultState, action: UserActionsTypes) => {
  switch (action.type) {

    case UserActions.AGREE_WITH_CONDITION:
      return { ...state, isAgreeWithConditions: !state.isAgreeWithConditions };

    case UserActions.SET_PHONE_NUMBER:
      return { ...state, userPhone: action.payload };

    case UserActions.SET_PIN_CODE:
      return { ...state, pinCode: action.payload };

    case UserActions.SET_PIN_COUNT_ACCESS:
      return { ...state, pinCountAccess: action.payload };

    default:
      return state;
  }
};

export default userReducer;
