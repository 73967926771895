import React from "react";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { AppRoutes } from "./routes";
import { GlobalStyle } from './styles/global-style';
import './locales/i18n';
import { Provider } from "react-redux";
import store, { persistor } from "./store";

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <GlobalStyle />
          <Header />
          <AppRoutes />
          <Footer />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}