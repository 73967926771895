import React, { useCallback, useEffect, useState } from "react"
import { Button } from "../../components/Button";
import { Trans, useTranslation } from 'react-i18next';
import { InfoBlocks } from "../../components/InfoBlocks";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useNavigate } from "react-router-dom";
import { useActions } from "../../hooks/useActions";
import { resetPinCodeRequest } from "../../helpers/api/api";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

interface IRecoverPageProps {
  onClick?: () => void;
}

export function RecoverPinPage(props: IRecoverPageProps) {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [limit, setLimit] = useState(false);
  const [isCodeSend, setIsCodeSend] = useState(false);
  const { setPinCountAccess, loadingHandler } = useActions();
  const [configData, setConfigData] = useState<pinCodeData>();
  const {
    formState: { errors },
    setError,
    clearErrors,
  } = useForm();
  const {
    userReducer: { pinCountAccess: counts }
  } = useTypedSelector((state) => state);

  const {
    deviceReducer: { deviceUID }
  } = useTypedSelector((state) => state);

  const {
    uiReducer: { isLoading }
  } = useTypedSelector((state) => state);

  const backToEnterPin = useCallback(() => {
    if (props.onClick) {
      props.onClick();
    }
  }, [props]);

  const goToPinPage = useCallback(() => {
    setLimit(false);
    setIsCodeSend(false);
    setPinCountAccess(3);
    navigate('/enter-pin', {
      state: { userExists: true }
    });
  }, [navigate, setPinCountAccess]);
  const {
    userReducer: { userPhone }
  } = useTypedSelector((state) => state);

  const handleRestore = useCallback(() => {
    loadingHandler();
    clearErrors();
    resetPinCodeRequest(configData as pinCodeData)
      .then(({ status, data }) => {
        console.log(data);
        if (status === 200) {
          setIsCodeSend(true);
        }
      })
      .catch(error => {
        if (error.response) {
          console.log(error.response);
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          switch (error.response.data.ids) {
            case 'code_recovery_attempts_exceeded': return (
              setError("pin_code", {
                type: "manual",
                message: t('translation.form.errorRecoveryAttemps'),
              })
            );
            case 'order_does_not_exist': return (
              setError("pin_code", {
                type: "manual",
                message: t('translation.form.errorRecoveryNotExists'),
              })
            );
            default: return (
              setError("pin_code", {
                type: "manual",
                message: t('translation.form.errorResponse400'),
              })
            );
          }
        }
      })
      .finally(() => {
        loadingHandler();
      });
  }, [configData, loadingHandler, clearErrors, setError, t]);

  useEffect(() => {
    setConfigData({
      phone: userPhone,
      device_id: deviceUID,
      device_uid: deviceUID,
      code_length: 4,
    });
  }, [deviceUID,userPhone]);

  useEffect(() => {
    if (counts === 0)
      setLimit(true);
    if (counts === 0 && isCodeSend && limit)
      setTimeout(() => {
        goToPinPage()
      }, 2000);
    if (counts !== 0)
      setTimeout(() => backToEnterPin(), 1000);
  }, [counts, goToPinPage, isCodeSend, limit, backToEnterPin])

  return (
    <>
      <div className="inside">
        {limit && isCodeSend ? (
          <>
            <div className="title title--type2">
              <p>{t('translation.recoverPinPage.restore')}</p>
            </div>
            <div className="title-text">
              <span>{userPhone}</span>
            </div>
          </>
        ) : (
          <>
            <div className="title title--type2">
              <p>{t('translation.recoverPinPage.title')}</p>
            </div>
            <div className="title-text">
              <p>
                {counts !== 0 ? (
                  <Trans
                    i18nKey={('translation.recoverPinPage.info')}
                    components={{counts}}
                  />
                ) : (
                  <>{t('translation.recoverPinPage.limit')}</>
                )}
              </p>
            </div>

              <InfoBlocks type="failed" />
              <ErrorMessage
                errors={errors}
                name="pin_code"
                render={
                  ({ message }) =>
                    <p className="error">
                      {message}
                    </p>
                }
              />
          </>
        )}

      </div>
      {isCodeSend && limit ? (
        <Button
          type="button"
          onClick={goToPinPage}
          text={t('translation.buttons.next')}
        />
      ) : (
        <Button
          type="button"
          onClick={!limit ? props.onClick : handleRestore}
            text={!limit ? t('translation.buttons.next') : t('translation.buttons.restorePin')}
            loading={isLoading}
        />
      )}

    </>
  )
}
