import React, { useCallback} from "react"
import { Button } from "../../components/Button";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export function NotFoundPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const goToMainPage = useCallback(
    () => navigate({ pathname: '/ ' }
  ), [navigate]);

  return (
    <>
      <div className="inside">
        <div className="title">
          <div onClick={goToMainPage}>
            404 - not found page
          </div>
        </div>
      </div>

      <Button
        type="button"
        onClick={goToMainPage}
        inverted
        text={t('translation.buttons.main')}
      />
    </>
  )
}
