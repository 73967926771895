import { createGlobalStyle } from 'styled-components';
import Formular from "../assets/fonts/Formular.ttf";
import FormularWOFF from "../assets/fonts/Formular.woff";
import FormularWOFF2 from "../assets/fonts/Formular.woff2";
import FormularEOT from "../assets/fonts/Formular.eot";
import FormularSVG from "../assets/fonts/Formular.svg";

import FormularBold from "../assets/fonts/Formular-Bold.ttf";
import FormularBoldWOFF from "../assets/fonts/Formular-Bold.woff";
import FormularBoldWOFF2 from "../assets/fonts/Formular-Bold.woff2";
import FormularBoldEOT from "../assets/fonts/Formular-Bold.eot";
import FormularBoldSVG from "../assets/fonts/Formular-Bold.svg";

import FormularMedium from "../assets/fonts/Formular-Medium.ttf";
import FormularMediumWOFF from "../assets/fonts/Formular-Medium.woff";
import FormularMediumWOFF2 from "../assets/fonts/Formular-Medium.woff2";
import FormularMediumEOT from "../assets/fonts/Formular-Medium.eot";
import FormularMediumSVG from "../assets/fonts/Formular-Medium.svg";

export const GlobalStyle = createGlobalStyle`

  @font-face {
      font-family: 'Formular';
      src: url(${FormularBold})format('truetype');
      src: local('Formular-Bold'), local('Formular'),
          url(${FormularBoldEOT})format('embedded-opentype'),
          url(${FormularBoldWOFF2})format('woff2'),
          url(${FormularBoldWOFF})format('woff'),
          url(${FormularBoldSVG})format('svg');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Formular';
      src: url(${FormularMedium})format('truetype');
      src: local('Formular-Medium'), local('Formular'),
          url(${FormularMediumEOT})format('embedded-opentype'),
          url(${FormularMediumWOFF2})format('woff2'),
          url(${FormularMediumWOFF})format('woff'),
          url(${FormularMediumSVG})format('svg');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Formular';
      src: url(${Formular})format('truetype');
      src: local('Formular'), local('Formular'),
          url(${FormularEOT})format('embedded-opentype'),
          url(${FormularWOFF2})format('woff2'),
          url(${FormularWOFF})format('woff'),
          url(${FormularSVG})format('svg');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
  }

  :root {
    --main-color: #5DB5E7;
    --success-color: #3FC0AA;
  }

  * { box-sizing: border-box; }

  html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    height: 100%;
    @media only screen
    and (device-width : 414px)
    and (device-height : 896px)
    and (-webkit-device-pixel-ratio : 2) {
      min-height: 99vh;
    }
  }

  body {
    margin: 0;
    font-family: 'Formular';
    height: 100%;
    background: white;
  }

  main {
    display: block;
  }

  h1 {
    font-size: 2em;
    margin: 0.67em 0;
  }

  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }


  pre {
    font-family: monospace, monospace;
    font-size: 1em;
  }

  a {
    background-color: transparent;
  }

  abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted;
  }

  b,
  strong {
    font-weight: bolder;
  }

  code,
  kbd,
  samp {
    font-family: monospace, monospace;
    font-size: 1em;
  }

  small {
    font-size: 80%;
  }

  p {
    margin: 0;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  img {
    border-style: none;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
  }

  button,
  input {
    overflow: visible;
    &:focus {
      outline: none;
    }
  }

  button,
  select {
    text-transform: none;
  }

  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }

  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  ol { 
    counter-reset: item;
    padding-left: 0;

    ol {
      padding-left: 15px;
    }
  }
  
  li { 
    display: block;
    
    &:not(:last-child) {
      margin-top: 10px;
    }
  }
  
  li:before { 
    content: counters(item, ".") " "; 
    counter-increment: item 
  }

  fieldset {
    padding: 0.35em 0.75em 0.625em;
  }

  legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;

  }

  progress {
    vertical-align: baseline;
  }

  textarea {
    overflow: auto;
  }

  [type="checkbox"],
  [type="radio"] {
    box-sizing: border-box;
    padding: 0;
  }

  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }

  [type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }

  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }


  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }

  details {
    display: block;
  }

  summary {
    display: list-item;
  }

  template {
    display: none;
  }
  body > iframe {
    z-index: -1 !important;
  }

  [hidden] {
    display: none;
  }
  :focus-visible, :focus {
    outline: none;
  }

  #root {
    overflow: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    .wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 30px;
      flex: 1;
    }
  }

  .inside {
    text-align: center;
  }

  .title {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 21px;
    margin-top: 20px;
    &-text {
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 1.4;

      a, span {
          font-size: 25px;
          display: block;
          color: rgba(0, 0, 0, 0.82);
      }
    }

    &--type2 {
        margin-bottom: 5px;
    }
  }

  .hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    clip: rect(0 0 0 0);
    overflow: hidden;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
  }

  .cells {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 4px;
    padding: 10px;
    background: white;
    border: 1px solid #2D2F30;
    margin-bottom: 20px;

    &__title {
        margin-bottom: 30px;
        font-weight: bold;
        font-size: 60px;
        color: var(--main-color);
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 112px;
        background: #2D2F30;

        font-weight: bold;
        font-size: 32px;
        color: #fff;

        &_main, &_pin, &_bag {
            background: var(--main-color);
        }

        &_success {
            background: #3FC0AA;
        }

        &_failed {
            background: #F53A46;
        }

        &_greey {
            background: #C0C1C1;
        }

        &_notComplete {
            background: #F97E3F;
        }
    }
}
.error {
    color: red;
    text-align: center;
    font-size: 16px;
    margin-top: 10px;
}
`;