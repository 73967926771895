import React from "react";
import { Route, Routes } from "react-router-dom";
import { CellNumberPage } from "../pages/CellNumberPage";
import { ChooseTariffPage } from "../pages/ChooseTariffPage";
import { EnterPinPage } from "../pages/EnterPinPage";
import { HomePage } from "../pages/HomePage";
import { InputNumberPage } from "../pages/InputNumberPage";
import { NoConnectionPage } from "../pages/NoConnectionPage";
import { NoEmptyCellPage } from "../pages/NoEmptyCellPage";
import { NotFoundPage } from "../pages/NotFoundPage";
import { NotPaidPage } from "../pages/NotPaidPage";
import { OfferPage } from "../pages/OfferPage";
import { OrderFailedPage } from "../pages/OrderFailed";
import { OrderNotCompletePage } from "../pages/OrderNotCompletePage";
import { OrderSuccessPage } from "../pages/OrderSuccess";
import { PlacePackagePage } from "../pages/PlacePackagePage";
import { RecoverPinPage } from "../pages/RecoverPinPage";
import { RulesPage } from "../pages/RulesPage";

export const AppRoutes: React.FC = () => (
  <div className="wrapper">
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/input-number" element={<InputNumberPage />} />
      <Route path="/rules" element={<RulesPage />} />
      <Route path="/cell-number" element={<CellNumberPage />} />
      <Route path="/choose-tariff" element={<ChooseTariffPage />} />
      <Route path="/offer" element={<OfferPage />} />
      <Route path="/no-empty-cells" element={<NoEmptyCellPage />} />
      <Route path="/no-connection" element={<NoConnectionPage />} />
      <Route path="/enter-pin" element={<EnterPinPage />} />
      <Route path="/recover-pin" element={<RecoverPinPage />} />
      <Route path="/place-package" element={<PlacePackagePage />} />
      <Route path="/order-success" element={<OrderSuccessPage />} />
      <Route path="/order-failed" element={<OrderFailedPage />} />
      <Route path="/order-not-complete" element={<OrderNotCompletePage />} />
      <Route path="/not-paid" element={<NotPaidPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  </div>
);
