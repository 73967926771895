import React from "react";
import { useLocation } from "react-router-dom";
import classNames from 'classnames';
import styles from './index.module.scss';
import logo from '../../assets/images/logo__footer.svg'
import logoInverted from '../../assets/images/logo__footer-inverted.svg'

export function Footer() {
  const location = useLocation();
  return (
    <footer className={classNames(styles.footer)}>
      <img
        src={
        location.pathname === '/'
          ? logoInverted
          : logo
        }
        alt="Omnic"
      />
    </footer>
  );
};
