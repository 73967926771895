import React, { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BackButton } from "../BackButton";
import classNames from 'classnames';
import styles from './index.module.scss';
import iconHome from '../../assets/images/icons/icon-home.svg';

export function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const goToPrevPage = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const goToMainPage = useCallback(() => {
    navigate('/');
  }, [navigate]);

  if (location.pathname === '/')
    return null;

  return (
    <header className={classNames(styles.header)}>
      <BackButton onClick={goToPrevPage} />
      <div
        className={classNames(styles.logo)}
        onClick={goToMainPage}
      >
        <div>
          <img src={iconHome} alt="Home" />
        </div>
      </div>
    </header>
  );
};
