import React, { useCallback, useLayoutEffect, useRef } from "react"
import classNames from 'classnames';
import styles from './index.module.scss';
import { LanguageSelector } from "../../components/LanguageSelector";
import iconHome from '../../assets/images/icons/icon-home.svg';
import { Button } from "../../components/Button";
import { useTranslation } from 'react-i18next';
import { useActions } from "../../hooks/useActions";
import { useNavigate, useLocation } from 'react-router-dom';
import useDeviceUidFromHash from "../../hooks/useDeviceUidFromHash";

export function HomePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const deviceUidFromHash = useDeviceUidFromHash();
  const { setDeviceUid } = useActions();
  const params = new URLSearchParams(useLocation().search);
  const device = params.get("device") || deviceUidFromHash;

  const goToNextPage = useCallback(
    () => navigate({ pathname: '/input-number' }
  ), [navigate]);
  const goToMainPage = useCallback(
    () => navigate({ pathname: '/'}
  ), [navigate]);

  const isMounted = useRef(true);

    useLayoutEffect(() => {
        if (isMounted) {
            if (!device) return;
            const  init = async () => {
                const sessionStatus = window.sessionStorage.getItem("deviceUID");
                if (sessionStatus === null) {
                    window.localStorage.clear();
                    setDeviceUid(device);
                    window.sessionStorage.setItem("deviceUID", "true");
                    // window.location.href = '/';
                    return;
                }
                setDeviceUid(device);
                // window.location.href = '/';
            };
            init();
        }
        return () => { isMounted.current = false; };
    }, [device, setDeviceUid, goToMainPage, navigate]);

  return (
    <div className={classNames(styles.main)}>
      <LanguageSelector />
      <div className={classNames(styles.logo)}>
        <div onClick={goToMainPage}>
          <img src={iconHome} alt="Home" />
        </div>
      </div>
      <Button
        type="button"
        onClick={goToNextPage}
        inverted
        text={t('translation.buttons.continue')}
      />
    </div>
  )
}
