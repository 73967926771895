import React, { useCallback } from "react"
import classNames from 'classnames';
import styles from './index.module.scss';
import { useNavigate } from 'react-router-dom';
import { Button } from "../../components/Button";
import { Trans, useTranslation } from 'react-i18next';

export function NotPaidPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const goToMain = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const hours = 2;
  const toPay = 40;

  return (
    <>
      <div className="inside">
        <div className={classNames(styles.notPaid)}>
          <div className={classNames(styles.notPaid__title)}>
            <p>
              <Trans
                i18nKey={('translation.notPaidPage.title')}
                components={{hours}}
              />
            </p>
          </div>

          <div className={classNames(styles.notPaid__toPaid)}>
            <p>{t('translation.notPaidPage.mustPay')}</p>
            <span>
              {toPay}
              <sub>{t('translation.currency')}</sub>
            </span>
          </div>
          <div className={classNames(styles.notPaid__tariff)}>
            <p>{t('translation.notPaidPage.tariff')} 1 година -20 {t('translation.currency')}</p>
          </div>
        </div>
      </div>
      <Button
        type="button"
        onClick={goToMain}
        text={t('translation.buttons.pay')}
      />
    </>
  )
}
