import { useLocation } from "react-router-dom";

export default function useDeviceUidFromHash() {
  const { hash } = useLocation();
  const hashWithoutSlash = hash.replace(/^#/, "").replace(/\//g, "");

  const REGEXP_UID =
    /[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}/g;

  return !!hashWithoutSlash?.match(REGEXP_UID) && hashWithoutSlash;
}
