import React, { useEffect, useRef, useState } from "react";

interface IIconProps {
  name: string;
}

export function Icon({name, ...props}: IIconProps) {
  const [iconModule, setIconModule] = useState<any>(null);
  const isMounted = useRef(true);

  useEffect(() => {
    if (isMounted)
      import(`../../assets/images/icons/icon-${name}.svg`)
        .then((module) => {
          setIconModule(module);
        })
        .catch((e) => {
          console.error(`Icon with name: ${name} not found!`);
        });

    return () => { isMounted.current = false; };
  }, [name]);

  const renderIcon = () => {
    if (!iconModule) return null;
    return <img {...props} src={iconModule.default} alt="rules-icon" />;
  };

  return <>{renderIcon()}</>;
}
