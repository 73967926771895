import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { InfoBlocks } from "../../components/InfoBlocks";
import { checkOrderRequest } from "../../helpers/api/api";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";

export function OrderFailedPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { loadingHandler } = useActions();
  const [configData, setConfigData] = useState<checkOrderData | orderPlacingData>();

  const {
    deviceReducer: { deviceUID }
  } = useTypedSelector((state) => state);

  const {
    uiReducer: { isLoading }
  } = useTypedSelector((state) => state);

  const {
    userReducer: { userPhone, pinCode }
  } = useTypedSelector((state) => state);

  useEffect(() => {
    setConfigData({
      recipient_phone: userPhone,
      recipient_code: pinCode,
      device_id: deviceUID,
      device_uid: deviceUID,
    });
  }, [
    deviceUID,
    pinCode,
    userPhone,
  ]);

  const checkOrder = useCallback(() => {
    loadingHandler();
    checkOrderRequest(configData as checkOrderData)
      .then(({ status, data }) => {
        console.log(data);
        if (status === 200) {
          console.log('Закрыта');
          navigate('/order-success');
        }
      })
      .catch(error => {
        if (error.response) {
          console.log('Открыта');
          navigate('/order-failed');
        }
      })
      .finally(() => {
        loadingHandler();
      });
  }, [
    configData,
    loadingHandler,
    navigate,
  ]);

  return (
    <>
      <div className="inside">
        <div className="title">
          <p>{t('translation.orderFailedPage.title')}</p>
        </div>
        <div className="title-text">
          <p>{t('translation.orderFailedPage.help')}</p>
        </div>
        <InfoBlocks type="failed"/>
      </div>

      <Button
        type="button"
        text={t('translation.buttons.cellClosed')}
        onClick={checkOrder}
        loading={isLoading}
      />
    </>
  )
}
