import { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import styles from "./index.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { InfoBlocks } from "../../components/InfoBlocks";
import { receiveOrderRequest } from "../../helpers/api/api";

export function CellNumberPage() {
  const [checkOrderConfig, setCheckOrderConfig] = useState<checkOrderData>();
  const [stopStorageLoading, setStopStorageLoading] = useState<boolean>(false);

  const { state: orderExists } = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    deviceReducer: { deviceUID, deviceData },
    userReducer: { userPhone, pinCode },
  } = useTypedSelector((state) => state);

  const goToNextPage = useCallback(() => {
    //TODO временно переходит на создание пин кода (из-за не соответствия в апи), а должно на выбор тарифа
    navigate("/enter-pin");
  }, [navigate]);

  // OCHN-1201 - remove continued use of cell
  // const chooseAction = useCallback(() => {
  //   navigate('/choose-action');
  // },[navigate]);

  const stopStorageEvent = useCallback(() => {
    setStopStorageLoading(true);
    receiveOrderRequest(checkOrderConfig as checkOrderData)
      .then(({ status, data }) => {
        console.log(data);
        if (status === 200) {
          navigate("/place-package", {
            state: { stopStorage: true },
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log("failed");
          navigate("/order-not-complete", {
            state: { stopStorage: true },
          });
        }
      })
      .finally(() => {
        setStopStorageLoading(true);
      });
  }, [checkOrderConfig, navigate]);

  useEffect(() => {
    setCheckOrderConfig({
      recipient_phone: userPhone,
      recipient_code: pinCode,
      device_id: deviceUID,
      device_uid: deviceUID,
    });
  }, [deviceUID, pinCode, userPhone]);

  return (
    <>
      <div className="inside">
        <div className={classNames(styles.gettingNumber)}>
          <div className={classNames(styles.gettingNumber__number)}>
            <p>{deviceData.cellNumber || "-"}</p>
          </div>
          <div className={classNames(styles.gettingNumber__title)}>
            <p>{t("translation.cellNumberPage.title")}</p>
          </div>
        </div>
        <InfoBlocks number={deviceData.cellNumber || "-"} />
      </div>

      <Button
        type="button"
        text={
          orderExists
            ? t("translation.buttons.openCell")
            : t("translation.buttons.continue")
        }
        onClick={orderExists ? stopStorageEvent : goToNextPage}
        loading={stopStorageLoading}
        disabled={!deviceData.cellNumber}
      />
    </>
  );
}
